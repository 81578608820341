
import { defineComponent } from 'vue'
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'
import router from '@/router'
import Multiselect from '@vueform/multiselect'
export default defineComponent({
  name: 'Vue Tailwind Pagination',
  components: { BaseIcon, Multiselect },
  props: {
    current: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 9
    },
    pageRange: {
      type: Number,
      default: 2
    },
    textBeforeInput: {
      type: String,
      default: 'Go to page'
    },
    hideInfoSection: {
      type: Boolean,
      default: false
    },
    basicPagination: {
      type: Boolean,
      default: false
    },
    hideInput: {
      type: Boolean,
      default: false
    }
  },
  mounted (): void {
    if (this.$route.query?.pageSize) {
      this.pageSize = Number(this.$route.query.pageSize)
    } else {
      this.pageSize = 10
    }
  },
  data () {
    return {
      input: '',
      pageSizes: [
        { id: 10, label: this.$t('pagination.perPage', { numb: 10 }) },
        { id: 20, label: this.$t('pagination.perPage', { numb: 20 }) },
        { id: 30, label: this.$t('pagination.perPage', { numb: 30 }) },
        { id: 50, label: this.$t('pagination.perPage', { numb: 50 }) }
      ],
      pageSize: 10
    }
  },
  methods: {
    hasFirst: function () {
      return this.rangeStart !== 1
    },
    hasLast: function () {
      return this.rangeEnd < this.totalPages
    },
    hasPrev: function () {
      return this.current > 1
    },
    hasNext: function () {
      return this.current < this.totalPages
    },
    changePage: function (page: number) {
      if (page > 0 && page <= this.totalPages) {
        this.$emit('page-changed', page)
      }
    }
  },
  watch: {
    pageSize (val: any): void {
      router.push({ query: { ...this.$route.query, pageSize: val } })
    }
  },
  computed: {
    pages: function () {
      const pages = []
      for (let i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i)
      }
      return pages
    },
    rangeStart: function () {
      const start = this.current - this.pageRange
      return (start > 0) ? start : 1
    },
    rangeEnd: function () {
      const end = this.current + this.pageRange
      return (end < this.totalPages) ? end : this.totalPages
    },
    totalPages: function () {
      return Math.ceil(this.total / this.perPage)
    },
    nextPage: function () {
      return this.current + 1
    },
    prevPage: function () {
      return this.current - 1
    },
    from: function () {
      return (this.current > 1 ? (this.current - 1) * this.perPage + 1 : 1)
    },
    to: function () {
      return this.current * this.perPage
    }
  }
})
